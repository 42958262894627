/*----- 3. Slider style  ------*/

.slider-height-1 {
    
    @media #{$xs-layout} {
      padding: 50px 0 0;
      &.slider-height-res15 {
        padding: 0 0 0;
      }
    }
    @media #{$sm-layout} {
      padding: 38px 0 0;
    }
    &.slider-height-res {
      @media #{$xs-layout} {
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: "";
          background-color: #fff;
          opacity: 0;
        }
      }
    }
  }
  
  .mobile-slider {
    @media #{$xs-layout} {
      margin-left: auto;
    }
  }
  
  .sm-justify-content-end {
    @media #{$xs-layout} {
      justify-content: end !important;
    }
  }
  
  .single-slider {
    aspect-ratio: 12/6;
    @media #{$xl-layout} {
      aspect-ratio: 23/6;
    }
    @media #{$lg-layout} {
      aspect-ratio: 23/6;
    }
    @media #{$md-layout} {
      aspect-ratio: 23/6;
      min-height: 24vh;
    }
    @media #{$xs-layout} {
      padding: 1rem;
      aspect-ratio: 9/9;
  
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .slider-content {
      text-align: center;
      margin-top: 5rem;
      @media #{$xs-layout} {
        margin-top: 0rem;
      }
      h3 {
        font-size: 40px;
        line-height: 3rem;
        font-family: $nextsphere;
        text-align: center;
        font-weight: normal;
        color: $secundary-color;
        @media #{$xs-layout} {
          font-size: 18.39px;
        }
      }
      p {
        
        font-family: $dinblack;
        color: $secundary-color;
        font-size: 22.18px;
        margin-bottom: 26px;
        @media #{$xs-layout} {
          font-size: 18.39px;
        }
      }
      h1 {
        font-size: 47px;
        font-family: $suigeneris;
        color: $third-color;
        margin: 6px 0 37px;
        @media #{$lg-layout} {
          line-height: 65px;
          font-size: 52px;
        }
        @media #{$md-layout} {
          line-height: 52px;
          font-size: 40px;
          margin: 6px 0 20px;
        }
        @media #{$xs-layout} {
          line-height: 48px;
          font-size: 24px;
          margin: 6px 0 20px;
        }
        @media #{$sm-layout} {
          line-height: 48px;
          font-size: 24px;
          margin: 6px 0 10px;
        }
      }
      .slider-btn {
        a {
            margin-top:5rem;
          font-size: 47px;
          font-family: $suigeneris;
          color: $theme-color;
          display: inline-block;
          text-transform: uppercase;
          line-height: 1;
          @media #{$xs-layout} {
            margin-top: 0rem;
    font-size: 25px;
          }

         
        }
      }
      &.slider-content-10 {
        padding-top: 0;
        @media #{$xs-layout} {
          text-align: center;
          display: block;
          width: 100%;
        }
        @media #{$sm-layout} {
          text-align: left;
        }
      }
    }
    .slider-single-img {
      margin: 0 -80px 0 -80px;
      @media #{$xl-layout} {
        margin: 0 0 0 0;
      }
      @media #{$lg-layout} {
        margin: 0 0 0 0;
      }
      @media #{$md-layout} {
        margin: 0 0 0 0;
      }
      @media #{$xs-layout} {
        margin: 0 0 0 0;
      }
    }
    &.single-slider-10 {
      align-items: center;
    }
  }
  
  .swiper-slide .slider-content * {
    -webkit-animation-duration: 1.3s;
    animation-duration: 1.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  
  .nav-style-1 {
    .ht-swiper-button-nav {
      width: 80px;
      height: 80px;
      padding: 0;
      color: #999;
      display: inline-block;
      font-size: 80px;
      left: 80px;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease 0s;
      background: none;
      border: none;
      @media #{$xl-layout} {
        left: 30px;
      }
      @media #{$lg-layout} {
        left: 30px;
      }
      @media #{$md-layout} {
        left: 15px;
        font-size: 60px;
      }
      @media #{$xs-layout} {
        left: 15px;
        font-size: 50px;
      }
      &:hover {
        color: $theme-color;
      }
      &.swiper-button-next {
        left: auto;
        right: 80px;
        @media #{$xl-layout} {
          right: 30px;
        }
        @media #{$lg-layout} {
          right: 30px;
        }
        @media #{$md-layout} {
          right: 15px;
        }
        @media #{$xs-layout} {
          right: 15px;
        }
      }
    }
  
    &.nav-testi-style {
      .owl-nav {
        div {
          font-size: 50px;
          left: -155px;
          color: #8d8c8c;
          @media #{$xl-layout} {
            left: -80px;
          }
          @media #{$lg-layout} {
            left: -80px;
          }
          @media #{$md-layout} {
            left: -30px;
          }
          @media #{$xs-layout} {
            display: none;
          }
          &:hover {
            color: #3d6882;
          }
          &.owl-next {
            right: -155px;
            left: auto;
            @media #{$xl-layout} {
              right: -80px;
            }
            @media #{$lg-layout} {
              right: -80px;
            }
            @media #{$md-layout} {
              right: -30px;
            }
          }
        }
      }
      &.nav-testi-mrg {
        .owl-nav {
          div {
            left: -80px;
            color: #fff;
            @media #{$md-layout} {
              left: 0px;
            }
            @media #{$xs-layout} {
              display: none;
            }
            &.owl-next {
              right: -80px;
              left: auto;
              @media #{$md-layout} {
                right: 0px;
                left: auto;
              }
              @media #{$xs-layout} {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .slider-area {
    &:hover .nav-style-1 .ht-swiper-button-nav {
      opacity: 1;
      visibility: visible;
    }
    &.ml-10 {
      margin-left: 0;
    }
    @media #{$xl-layout} {
      &.ml-70 {
        margin-left: 15px;
      }
      &.mr-70 {
        margin-right: 15px;
      }
    }
    @media #{$lg-layout} {
      &.ml-70 {
        margin-left: 30px;
      }
      &.mr-70 {
        margin-right: 30px;
      }
    }
    @media #{$md-layout} {
      &.ml-70 {
        margin-left: 40px;
      }
      &.mr-70 {
        margin-right: 40px;
      }
    }
    @media #{$xs-layout} {
      &.ml-70 {
        margin-left: 15px;
      }
      &.mr-70 {
        margin-right: 15px;
      }
    }
  
    .swiper-pagination-bullets {
      bottom: 26px;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      .swiper-pagination-bullet {
        background: #fff none repeat scroll 0 0;
        opacity: 1;
        border-radius: 100%;
        display: inline-block;
        height: 14px;
        margin: 0 8px;
        width: 14px;
        position: relative;
        &:before {
          position: absolute;
          content: "";
          left: -3px;
          right: -3px;
          top: -3px;
          bottom: -3px;
          border: 1px solid transparent;
          border-radius: 100%;
        }
        &-active:before {
          border: 1px solid #fff;
        }
      }
    }
  }
  