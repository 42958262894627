@import "react-nice-dates/src/style.scss";
.nice-dates-day.-highlight {
  color: orange;
  pointer-events: all !important;
  cursor: pointer!important;
}
.nice-dates-navigation {
  align-items: center;
  color: #0e3254;
  display: flex;
  justify-content: space-between;
  position: relative;
  text-align: center;
  text-transform: capitalize;
}
.nice-dates-week-header {
  // box-shadow: 0 1px 0 rgb(0 0 0 / 6%);
  display: flex;
  padding: 8px 0;
}
.nice-dates-grid {
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  user-select: none;
  height: 360px;
}

.nice-dates-grid_container {
  backface-visibility: hidden;
  display: flex;
  flex-wrap: wrap;
  left: 0;
  position: absolute;
  right: 0;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: none;
  will-change: transform;
  transform: translate3d(0px, 0px, 0px);
  transition-duration: 500ms;
}

.w-40 {
  width: 40%;
}

.hours-column{
  button{
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    background: transparent;
    border: 1px solid $theme-color;
    &:hover{
      background-color: $theme-color;
      color: #fff;
    }
  }

}



.booking-container{
  min-width: 900px;
    max-width: 1060px;
    @media #{$xs-layout} {
   width:100%;
   flex-wrap:wrap;
    }
}

.service-info-container{
  @media #{$xs-layout} {
    width:100%;
     }
  h2{
    font-size: 28px;
    line-height: 32px;
    color: $theme-color;
  }
  h6{
    font-size: 12px;
    line-height: 20px;
    margin-top: 0.5rem;
    color: rgb(147, 149, 151);
  }
  i{
    font-size:22px;
    color: rgb(147, 149, 151);
  }
  p{
    font-size: 15px;
    color: rgb(147, 149, 151);
  }
  a{
    margin-bottom: 24px;
   
  }
}

.button-signup{
  .MuiCircularProgress-root {
    width: 25px !important;
    height: 25px !important;
  }
  width: 91px;
  height: 54px;
}